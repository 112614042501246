import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, retry, RetryConfig } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AllNotificationGetRequest } from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models/all-notification-get-request';
import { ApiService } from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { NotificationInfo } from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models/notification-info';
import { UserInfoService } from '../../../../../services/user-info.service';
import { NotificationInfoModel, NotificationReceiveInfoModel, Result } from '../../../../../models/models';
import { CommonService } from '../../../../../../../../goldstar-share/src/app/services/common.service';
import { ResultHelper } from '../../../../../../../../goldstar-share/src/app/common/result-extension';
import { NotificationModel } from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models/notification-model';
import {
	ConnectedUserDetail,
	FetchNotificationTopicModel,
	MarkNotificationAsDeliveredRequest,
	NotificationDetailInfo,
	NotificationForTopicRequest,
	NotificationForUserGroupModel,
	NotificationForUserModel,
	NotificationGetRequest,
	NotificationGroupModel,
	NotificationTopicInfo,
	NotificationTopicRequestModel,
	NotificationTopicSubscriberInfo,
	NotificationTopicSubscriberRequestModel,
	NotificationTopicSubscriberUpdateInfo,
	NotificationTopicUpdateRequestModel,
	NotificationUpdateModel,
	UserGroupNotificationMessage,
	UserNotificationMessage,
} from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import moment from 'moment';
import { DeleteRequest, EntityGetRequest } from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { UserAndGroupInfoModel, UserInfoType } from '../../../../../models/interfaces';
import { SendUserMessage } from './notificationModel';
import { NotificationDataStore } from './notification-data.store';
import { environment } from '../../../../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	public subject!: WebSocketSubject<unknown>;
	private readonly defaultItem: UserAndGroupInfoModel[] = [];
	public allNotificationTopics: BehaviorSubject<UserAndGroupInfoModel[]> = new BehaviorSubject(this.defaultItem);

	constructor(private apiV2: ApiService, private userInfoService: UserInfoService, private commonService: CommonService, private notificationDataStore: NotificationDataStore) {}

	public sendMessage(message: any) {
		// const socketUrl = `wss://euoyn993tj.execute-api.us-east-1.amazonaws.com/production/`;
		const socketUrl = `${environment.webSocketApiUri}?AuthQuery=${environment.apiRoot}`;
		this.subject = webSocket(socketUrl);
		this.subject.next(message);
		return this.subject;
	}

	// Process all messages of type = 'Notification'
	public async addNotificationMessage(message: SendUserMessage) {
		if (!message) return;
		const matchingFromUser = (await this.userInfoService.fetchAllUsers()).find((x) => x.internalUserGUID === message.fromUserGUID);
		const notificationInfoModel: NotificationReceiveInfoModel = {
			fromUserGUID: message.fromUserGUID,
			fromUserName: matchingFromUser?.name ?? '',
			level: message.level,
			messageBody: message.messageBody,
			messageSendTime: message.messageSendTime,
			mode: message.mode,
			readYN: message.readYN == 'Y' ? true : false,
			toUserGUID: '',
			type: message.type,
			notificationGUID: message.notificationGUID,
			topicName: '',
			systemCode: message.systemCode,
			category: message.category,
			entityKeyProp: message.entityKeyProp,
			entityKeyValue: message.entityKeyValue,
			redirectComponent: message.redirectComponent,
			silentYN: message.silentYN,
		};
		this.notificationDataStore.addNotification(notificationInfoModel);
	}

	// Process all messages of type = 'NotificationTopic'
	public async addNotificationTopicMessage(message: SendUserMessage) {
		if (!message) return;
		const matchingFromUser = (await this.userInfoService.fetchAllUsers()).find((x) => x.internalUserGUID === message.fromUserGUID);
		const notificationInfoModel: NotificationReceiveInfoModel = {
			fromUserGUID: message.fromUserGUID,
			fromUserName: matchingFromUser?.name ?? '',
			level: message.level,
			messageBody: message.messageBody,
			messageSendTime: message.messageSendTime,
			mode: message.mode,
			readYN: message.readYN === 'Y' ? true : false,
			toUserGUID: '',
			type: message.type,
			notificationGUID: message.notificationGUID,
			topicName: message.topicName ?? '',
			systemCode: message.systemCode,
			category: message.category,
			entityKeyProp: message.entityKeyProp,
			entityKeyValue: message.entityKeyValue,
			redirectComponent: message.redirectComponent,
			silentYN: message.silentYN,
		};
		this.notificationDataStore.addNotification(notificationInfoModel);
	}

	/**
	 * Persist a notification
	 * @param request
	 * @returns
	 */
	public async saveNotification(request: NotificationModel): Promise<Result<string>> {
		return await this.commonService.toResultData(async () => {
			const response = await lastValueFrom(this.apiV2.notificationAdd({ body: request }));
			if (response.isSuccess) {
				return ResultHelper.successResponse<string[]>([]);
			}
			throw Error('Failed to save notification');
		});
	}

	public async fetchSelectedNotificationTopic(request: FetchNotificationTopicModel): Promise<Result<NotificationTopicInfo[]>> {
		return await this.commonService.toResultData(async () => {
			const response = await lastValueFrom(this.apiV2.selectedNotificationTopic({ body: request }));
			if (response.isSuccess && response.data) {
				return ResultHelper.successResponse<NotificationTopicInfo[]>(response.data.items ?? []);
			}
			throw Error('Failed to load topic notification');
		});
	}

	public async fetchSelectedNotificationTopicSubscriber(request: FetchNotificationTopicModel): Promise<Result<NotificationTopicSubscriberInfo[]>> {
		return await this.commonService.toResultData(async () => {
			const response = await lastValueFrom(this.apiV2.selectedNotificationTopicSubscriber({ body: request }));
			if (response.isSuccess && response.data) {
				return ResultHelper.successResponse<NotificationTopicSubscriberInfo[]>(response.data.items ?? []);
			}
			throw Error('Failed to load topic notification');
		});
	}

	/**
	 * Persists a group notification
	 * @param request
	 * @returns
	 */
	public async saveGroupNotification(request: NotificationGroupModel): Promise<Result<string>> {
		return await this.commonService.toResultData(async () => {
			const response = await lastValueFrom(this.apiV2.notificationGroupAdd({ body: request }));
			if (response.isSuccess) {
				return ResultHelper.successResponse<string[]>([]);
			}
			throw Error('Failed to save group notification');
		});
	}

	public async getAllNotificationForAUser(request: NotificationForUserModel): Promise<Result<NotificationInfoModel[]>> {
		return await this.commonService.toResultData<NotificationInfoModel[]>(async () => {
			const response = await lastValueFrom(this.apiV2.allNotificationListForAUser({ body: request }));
			if (response.isSuccess && response.data) {
				const notificationInfo = response.data.items;
				const notificationList = notificationInfo?.map((notificationInfo: NotificationInfo) => {
					const notificationModel: NotificationInfoModel = {
						level: notificationInfo.level ?? '',
						messageBody: notificationInfo.messageBody ?? '',
						notificationSendTime: moment(notificationInfo.messageSendTime).format('YYYY-MM-DD HH:mm:ss'),
					};
					return notificationModel;
				});
				return ResultHelper.successResponse<NotificationInfoModel[]>(notificationList ?? []);
			}
			throw Error('Failed to load user notification data');
		});
	}

	public async getAllNotificationForAUserForUserGroup(request: NotificationForUserGroupModel): Promise<Result<NotificationInfoModel[]>> {
		return await this.commonService.toResultData<NotificationInfoModel[]>(async () => {
			const response = await lastValueFrom(this.apiV2.allNotificationListForAUserForAGroup({ body: request }));
			if (response.isSuccess && response.data) {
				const notificationInfo = response.data.items;
				const notificationList = notificationInfo?.map((notificationInfo: NotificationInfo) => {
					const notificationModel: NotificationInfoModel = {
						level: notificationInfo.level ?? '',
						messageBody: notificationInfo.messageBody ?? '',
						notificationSendTime: moment(notificationInfo.messageSendTime).format('YYYY-MM-DD HH:mm:ss'),
					};
					return notificationModel;
				});
				return ResultHelper.successResponse<NotificationInfoModel[]>(notificationList ?? []);
			}
			throw Error('Failed to load user notification data');
		});
	}

	public async addNotificationTopic(request: NotificationTopicRequestModel): Promise<Result<string>> {
		return await this.commonService.toResultData(async () => {
			const response = await lastValueFrom(this.apiV2.notificationTopicAdd({ body: request }));
			if (response.isSuccess && response.data) {
				const userAndGroupModel: UserAndGroupInfoModel = {
					identifierGUID: response.data ?? '',
					name: request.topicName ?? null,
					description: request.topicDescription ?? null,
					type: UserInfoType.NotificationTopic,
				};

				// Updating the store value for all notification topics
				const value = this.allNotificationTopics.getValue();
				value.push(userAndGroupModel);
				this.allNotificationTopics.next(value);

				return ResultHelper.successResponse<string>(response.data ?? '');
			}
			throw Error('Failed to save notification topic');
		});
	}

	public async updateNotificationTopic(request: NotificationTopicUpdateRequestModel): Promise<Result<string>> {
		return await this.commonService.toResultData(async () => {
			const response = await lastValueFrom(this.apiV2.notificationTopicUpdate({ body: request }));
			if (response.isSuccess && response.data) {
				// Updating the store value for all notification topics
				const value = this.allNotificationTopics.getValue();
				const matchingItem = value.find((x) => x.name === request.notificationTopicGUID);
				if (matchingItem) {
					const matchingItemIndex = value.indexOf(matchingItem);
					matchingItem.name = request.topicName ?? '';
					matchingItem.description = request.topicDescription ?? '';
					value[matchingItemIndex] = matchingItem;
					this.allNotificationTopics.next(value);
				}
				return ResultHelper.successResponse<string>(response.data ?? '');
			}
			throw Error('Failed to save notification topic');
		});
	}

	public async deleteNotificationTopic(request: DeleteRequest): Promise<Result<string>> {
		return await this.commonService.toResultData(async () => {
			const response = await lastValueFrom(this.apiV2.notificationTopicDelete({ body: request }));
			if (response.isSuccess && response.data) {
				// Updating the store value for all notification topics
				const allNotificationTopics = this.allNotificationTopics.getValue();
				const filterList = allNotificationTopics.filter((x) => x.identifierGUID != request.guidToDelete);
				this.allNotificationTopics.next(filterList);

				return ResultHelper.successResponse('Successfully deleted notification topic');
			}
			throw Error('Failed to save notification topic');
		});
	}

	public async addNotificationTopicSubscribers(request: NotificationTopicSubscriberRequestModel): Promise<Result<string>> {
		return await this.commonService.toResultData(async () => {
			const response = await lastValueFrom(this.apiV2.notificationTopicSubscriberAdd({ body: request }));
			if (response.isSuccess) {
				return ResultHelper.successResponse<string[]>([]);
			}
			throw Error('Failed to save subscribers for notification topic');
		});
	}

	public async updateNotificationTopicSubscriber(request: NotificationTopicSubscriberUpdateInfo): Promise<Result<string>> {
		return await this.commonService.toResultData(async () => {
			const response = await lastValueFrom(this.apiV2.notificationTopicSubscriberUpdate({ body: request }));
			if (response.isSuccess) {
				return ResultHelper.successResponse<string[]>([]);
			}
			throw Error('Failed to save subscribers for notification topic');
		});
	}

	/**
	 * Fetches all the notification topic and updates the subject
	 * @param request
	 * @returns
	 */
	public async initializeAllNotificationTopics(request: EntityGetRequest): Promise<Result<string>> {
		return this.commonService.toResultData<string>(async () => {
			const allNotificationTopics = this.allNotificationTopics.getValue();
			if (allNotificationTopics.length >= 1) return ResultHelper.successResponse<UserAndGroupInfoModel[]>(allNotificationTopics ?? []);

			console.log('Reloading notifications from Data store');
			const response = await lastValueFrom(this.apiV2.allNotificationTopic({ body: request }));
			if (response.isSuccess && response.data) {
				const notificationInfo = response.data.items;
				const notificationTopicList = notificationInfo?.map((notificationTopicInfo: NotificationTopicInfo) => {
					const userAndGroupModel: UserAndGroupInfoModel = {
						identifierGUID: notificationTopicInfo.notificationTopicGUID ?? '',
						name: notificationTopicInfo.topicName ?? null,
						description: notificationTopicInfo.topicDescription ?? null,
						type: UserInfoType.NotificationTopic,
					};
					return userAndGroupModel;
				});
				this.allNotificationTopics.next(notificationTopicList ?? []);
				return ResultHelper.successResponse<string>('Successfully fetched all notification topics');
			}
			throw Error('Failed to load user notification data');
		});
	}

	/**
	 * Fetches all the notification topic
	 * @param request
	 * @returns
	 */
	public async fetchAllNotificationTopics(request: EntityGetRequest): Promise<Result<UserAndGroupInfoModel[]>> {
		return this.commonService.toResultData<UserAndGroupInfoModel[]>(async () => {
			// const allNotificationTopics = this.allNotificationTopics.getValue();
			// if (allNotificationTopics.length >= 1) return ResultHelper.successResponse<UserAndGroupInfoModel[]>(allNotificationTopics ?? []);

			console.log('Reloading notifications from Data store');
			const response = await lastValueFrom(this.apiV2.allNotificationTopic({ body: request }));
			if (response.isSuccess && response.data) {
				const notificationInfo = response.data.items;
				const notificationTopicList = notificationInfo?.map((notificationTopicInfo: NotificationTopicInfo) => {
					const userAndGroupModel: UserAndGroupInfoModel = {
						identifierGUID: notificationTopicInfo.notificationTopicGUID ?? '',
						name: notificationTopicInfo.topicName ?? null,
						description: notificationTopicInfo.topicDescription ?? null,
						type: UserInfoType.NotificationTopic,
					};
					return userAndGroupModel;
				});
				return ResultHelper.successResponse<UserAndGroupInfoModel[]>(notificationTopicList ?? []);
			}
			throw Error('Failed to load user notification data');
		});
	}

	public async getAllNotificationForTopic(request: NotificationForTopicRequest): Promise<Result<NotificationInfoModel[]>> {
		return await this.commonService.toResultData<NotificationInfoModel[]>(async () => {
			const response = await lastValueFrom(this.apiV2.allNotificationForTopicFromSender({ body: request }));
			if (response.isSuccess && response.data) {
				const notificationInfo = response.data.items;
				const notificationList = notificationInfo?.map((notificationInfo: NotificationInfo) => {
					const notificationModel: NotificationInfoModel = {
						level: notificationInfo.level ?? '',
						messageBody: notificationInfo.messageBody ?? '',
						notificationSendTime: moment(notificationInfo.messageSendTime).format('YYYY-MM-DD HH:mm:ss'),
					};
					return notificationModel;
				});
				return ResultHelper.successResponse<NotificationInfoModel[]>(notificationList ?? []);
			}
			throw Error('Failed to load user notification data');
		});
	}

	public async markNotificationAsDelivered(request: NotificationReceiveInfoModel): Promise<Result<string>> {
		return await this.commonService.toResultData<string>(async () => {
			const loggedInUser = await this.userInfoService.getCurrentLoggedInUser();
			const updateRequest: MarkNotificationAsDeliveredRequest = {
				notificationGUID: request.notificationGUID ?? '',
				type: request.type,
				toUserGUID: loggedInUser.internalUserGUID,
			};
			const response = await lastValueFrom(this.apiV2.markNotificationDelivered({ body: updateRequest }));
			return response;
		});
	}

	public async notificationDetailList(request: AllNotificationGetRequest): Promise<Result<NotificationDetailInfo[]>> {
		return await this.commonService.toResultData<NotificationDetailInfo[]>(async () => {
			const response = await lastValueFrom(this.apiV2.notificationDetailList({ body: request }));
			if (response.isSuccess && response.data) {
				const notificationInfo = response.data.items;
				return ResultHelper.successResponse<NotificationDetailInfo[]>(notificationInfo ?? []);
			}
			throw Error('Failed to load user notification data');
		});
	}

	/**
	 * Marks the notification as read
	 * @param request
	 * @returns
	 */
	public async markNotificationAsRead(request: NotificationUpdateModel): Promise<Result<string>> {
		return await this.commonService.toResultData<string>(async () => {
			await lastValueFrom(this.apiV2.markNotificationAsRead({ body: request }))
				.then((response) => {
					if (response.isSuccess && response.data) {
						this.notificationDataStore.markNotificationAsRead(request);
					} else {
						throw 'Error marking notification as read';
					}
				})
				.catch((error: any) => {
					throw Error(error);
				});
		});
	}

	public async fetchNotificationDetailInfo(request: NotificationGetRequest): Promise<Result<NotificationDetailInfo>> {
		return this.commonService.toResultData<NotificationDetailInfo>(async () => {
			const response = await lastValueFrom(this.apiV2.notificationDetail({ body: request }));
			if (!response.isSuccess || !response.data || !response.data.items) throw Error('Failed to fetch selected notification');
			return ResultHelper.successResponse<NotificationDetailInfo>(response.data?.items[0]);
		});
	}

	public async sendUserMessage(request: UserNotificationMessage): Promise<Result<string>> {
		return this.commonService.toResultData<string>(async () => {
			const response = await lastValueFrom(this.apiV2.sendUserNotification({ body: request }));
			if (!response.isSuccess || !response.data) throw Error('Failed to send notification');
			return ResultHelper.successResponse<string>(response.data);
		});
	}

	public async sendUserGroupMessage(request: UserGroupNotificationMessage): Promise<Result<string>> {
		return this.commonService.toResultData<string>(async () => {
			const response = await lastValueFrom(this.apiV2.sendUserGroupNotification({ body: request }));
			if (!response.isSuccess || !response.data) throw Error('Failed to send group notification');
			return ResultHelper.successResponse<string>(response.data);
		});
	}

	public async fetchAllConnectedUsers(request: EntityGetRequest): Promise<Result<ConnectedUserDetail[]>> {
		return await this.commonService.toResultData<ConnectedUserDetail[]>(async () => {
			const response = await lastValueFrom(this.apiV2.allConnectedUsers({ body: request }));
			if (response.isSuccess && response.data) {
				const connectedUsers = response.data.items;
				return ResultHelper.successResponse<ConnectedUserDetail[]>(connectedUsers ?? []);
			}
			throw Error('Failed to fetch all connected users');
		});
	}
}
