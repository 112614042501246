import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NotificationReceiveInfoModel } from '../../../../../models/models';
import { AppState } from '../../../../../models/classes';
import { Store } from '@ngrx/store';
import { NotificationAddedAction, NotificationsUpdatedAction } from '../../../../../store/store.actions';
import { NotificationUpdateModel } from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models/notification-update-model';

@Injectable({
	providedIn: 'root',
})
export class NotificationDataStore {
	private notification!: NotificationReceiveInfoModel;
	private clonedNotification!: NotificationReceiveInfoModel;
	private notificationList: NotificationReceiveInfoModel[] = [];
	private clonedNotificationList: NotificationReceiveInfoModel[] = [];
	public $notificationSubscription: BehaviorSubject<NotificationReceiveInfoModel> = new BehaviorSubject(this.notification);

	constructor(private store: Store<AppState>) {}

	addNotification(notification: NotificationReceiveInfoModel) {
		if (notification) {
			this.notificationList.push(notification);
			this.clonedNotificationList = JSON.parse(JSON.stringify(this.notificationList));
			this.notification = notification;
			this.clonedNotification = JSON.parse(JSON.stringify(this.notification));
			this.store.dispatch(NotificationsUpdatedAction({ notifications: this.clonedNotificationList }));
			this.store.dispatch(NotificationAddedAction({ notification: this.clonedNotification }));
		}
	}

	markNotificationAsRead(notification: NotificationUpdateModel) {
		if (notification) {
			const matchingNotification = this.notificationList.find((x) => x.notificationGUID == notification.notificationGUID);
			if (matchingNotification) {
				const indexOfNotification = this.notificationList.indexOf(matchingNotification);
				matchingNotification.readYN = true;
				this.notificationList[indexOfNotification] = matchingNotification;
				this.clonedNotificationList = JSON.parse(JSON.stringify(this.notificationList));
				this.store.dispatch(NotificationsUpdatedAction({ notifications: this.clonedNotificationList }));
			}
		}
	}

	initializeNotificationList(notifications: NotificationReceiveInfoModel[]) {
		this.notificationList = notifications;
		this.clonedNotificationList = JSON.parse(JSON.stringify(this.notificationList));
		this.store.dispatch(NotificationsUpdatedAction({ notifications: this.clonedNotificationList }));
	}
}
